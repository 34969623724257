import React, { useState } from 'react';
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
export const PasswordInput = (control: any) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClick = () => setShow(!show);
  return <Controller control={control} name="password" render={({
    field
  }) => <InputGroup>
          <Input pr="4.5rem" type={show ? 'text' : 'password'} placeholder="Insira sua palavra-passe" {...field}
    // {...register('password', {
    //   required: { value: true, message: 'É necessário fornecer uma palavra-passe' },
    //   minLength: { value: 6, message: 'A palavra-passe tem de ter pelo menos 6 caracteres' },
    // })}
    />
          <InputRightElement width="4.5rem" mr={1}>
            <Button h="1.75rem" onClick={handleClick} colorScheme="purple">
              {show ? 'Esconder' : 'Revelar'}
            </Button>
          </InputRightElement>
        </InputGroup>} data-sentry-element="Controller" data-sentry-component="PasswordInput" data-sentry-source-file="password-input.comp.tsx" />;
};